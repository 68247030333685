var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown show"},[_c('div',{staticClass:"dropdown-menu sidebar-item-warning-message",attrs:{"data-testid":"warning-text"}},[(_vm.isLocked)?_c('p',{staticClass:"text"},[_c('gl-sprintf',{attrs:{"message":_vm.__(
            'Unlock this %{issuableDisplayName}? %{strongStart}Everyone%{strongEnd} will be able to comment.'
          )},scopedSlots:_vm._u([{key:"issuableDisplayName",fn:function(){return [_vm._v(_vm._s(_vm.issuableDisplayName))]},proxy:true},{key:"strong",fn:function(ref){
          var content = ref.content;
return [_c('strong',[_vm._v(_vm._s(content))])]}}],null,false,2657919220)})],1):_c('p',{staticClass:"text"},[_c('gl-sprintf',{attrs:{"message":_vm.__(
            'Lock this %{issuableDisplayName}? Only %{strongStart}project members%{strongEnd} will be able to comment.'
          )},scopedSlots:_vm._u([{key:"issuableDisplayName",fn:function(){return [_vm._v(_vm._s(_vm.issuableDisplayName))]},proxy:true},{key:"strong",fn:function(ref){
          var content = ref.content;
return [_c('strong',[_vm._v(_vm._s(content))])]}}])})],1),_vm._v(" "),_c('edit-form-buttons',{attrs:{"is-locked":_vm.isLocked,"issuable-display-name":_vm.issuableDisplayName}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }